<template>
  <div>
    <v-row>
      <v-col cols="4">
        <v-skeleton-loader
          v-bind="attrs"
          type="text"
        />
      </v-col>
      <v-col cols="4">
        <v-skeleton-loader
          v-bind="attrs"
          type="text"
        />
      </v-col>
      <v-col cols="2">
        <v-skeleton-loader
          v-bind="attrs"
          type="button"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-skeleton-loader
          v-bind="attrs"
          type="card-heading, divider"
        />
      </v-col>
      <v-col cols="12" sm="4">
        <v-skeleton-loader
          v-bind="box"
          outlined
          type="card-heading, divider, list-item-avatar@3"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: "RoleFormSkeleton",
  data() {
    return {
      attrs: {
        class: 'my-2',
      },
      box: {
        class: 'my-2',
        elevation: 1,
      }
    }
  }
}
</script>