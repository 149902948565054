<template>
  <v-container class="container--fluid grid-list-md">
    <v-row no-gutters>
      <v-col cols="12" class="mb-2">
        <span class="font-weight-medium text-body-1 primary--text text--darken-1">{{ $t('roles.rolesActions') }}</span>
      </v-col>
    </v-row>
    <v-row no-gutters v-if="(roleId && !!model.is_locked && model.user_id!==user.id)">
      <v-col cols="12" class="my-3">
        <span class="font-weight-medium text-body-1 primary--text text--darken-1 error--text" >*{{ $t('common.documentReadOnlyModeNotice',{userId:model.user.name}) }}</span>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <v-card
          class="mx-auto"
          outlined
        >
          <v-card-title class="py-1">
            <h5 style="max-width: 220px;" :class="roleId ? 'px-1 text-truncate font-italic primary--text font-weight-bold' : 'primary--text text--darken-1'" class="font-weight-regular">{{ roleId ? originModel.name && `${roleId}. ${originModel.name}`  : $t('roles.addRole') }}</h5>
            <v-spacer />
            <v-btn
              class="mt-2"
              color="primary"
              small
              :loading="loading"
              :disabled="!valid || (!!model.is_locked && model.user_id!==user.id)"
              @click="saveRole"
            >
              {{ $t('roles.save') }}
            </v-btn>
          </v-card-title>
          <v-divider/>
          <v-card-text class="text-h5">
            <RoleFormSkeleton v-if="roleId && Object.keys(roleById).length <= 0"/>
            <v-form
              v-else
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <v-row>
                <v-col cols="4" :dense="true">
                  <v-text-field
                    hide-details="auto"
                    v-model.trim="model.name"
                    :label="$t('roles.roleName')"
                    :rules="roleValid"
                    required
                  />
                </v-col>
                <v-col cols="4" :dense="true">
                  <v-text-field
                    hide-details="auto"
                    v-model.trim="model.name_he"
                    :label="$t('roles.roleHeName')"
                    :rules="roleHebrewValid"
                    required
                  />
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    hide-details="auto"
                    v-model="model.description"
                    :label="$t('roles.description')"
                    :rules="[lessThanValidation($t('roles.description'), $t('common.less'), 255, model.description)]"
                    required
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-card-subtitle class="px-0 py-2">
                    <h3 class="primary--text font-weight-regular">{{$t('roles.rolePermissions')}}</h3>
                  </v-card-subtitle>
                  <v-divider class="mb-2 primary" />
                </v-col>
                <v-col cols="12" sm="4">
                  <v-card
                    class="mx-auto"
                    outlined
                  >
                    <v-card-text class="pa-0" v-for="(item, index) in permissionModel" :key="item.id">
                      <v-card-title v-if="index === 0" class="py-1">
                        <h5 class="primary--text text--darken-1 font-weight-regular">{{permissionModel[index].title}}</h5>
                        <v-spacer />
                      </v-card-title>
                      <v-divider class="pb-1" v-if="index === 0"/>
                      <v-checkbox
                        :dense="true"
                        class="mx-2 mt-0"
                        v-model="permission_ids"
                        :label="permissionModel[index].slug"
                        :value="permissionModel[index].id"
                      ></v-checkbox>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import RoleFormSkeleton from '@/components/skeleton/RoleFormSkeleton';
import {changedFormData} from '@/utils';

export default {
  name: 'RolesAction',
  components: {
    RoleFormSkeleton
  },
  data() {
    return {
      loading: false,
      valid: false,
      model: {},
      originModel: {},
      permission_ids: [],
      newRole: {},
    }
  },
  computed: {
    roleValid() {
      return [
        v => !!v || this.$t('formRules.companyRequired'),
        v => !/^\s*$/.test(v) || this.$t('formRules.companyRequired'),
        v => !v || (v && v.length <= 255) || this.$t('formRules.lengthValidMessage', { fieldName: this.$t('roles.roleName'), type: this.$t('common.less'), size: 255 }),
      ];
    },
    roleHebrewValid() {
      return [
        v => !v || !/^\s*$/.test(v) || this.$t('formRules.roleRequired'),
        v => !v || (v && v.length <= 255) || this.$t('formRules.lengthValidMessage', { fieldName: this.$t('roles.roleHeName'), type: this.$t('common.less'), size: 255 }),
      ];
    },
    roleId() {
      return this.$route.params.id
    },
    ...mapGetters({
      roleById: 'roleById',
      permissionModel: 'allPermissions',
      roleQuery: 'roleQuery',
      user: 'user',
      moreThanOneUserLogout: 'moreThanOneUserLogout'
    }),
  },
  async mounted() {    
    if (this.$route.params.id) {
      await this.$store.dispatch('GetRoleById', this.$route.params.id);
      if (!this.model.is_locked && this.$route.params.id) {
        await this.$store.dispatch('PostDocumentOpenAction',{table:'Role',id:this.$route.params.id,is_locked:true});
      }
    }
    await this.$store.dispatch('GetAllPermissions');
  },
  watch: {
    roleById() {
      if (Object.keys(this.roleById).length > 0) {
        this.model = Object.assign({}, this.model, this.roleById)
        if(this.roleById.permissions.length > 0) {
          this.roleById.permissions?.forEach(item => {
            this.permission_ids.push(item.id);
          });
        }
        this.originModel = Object.assign({}, this.originModel, this.model);
      }
    },
    async moreThanOneUserLogout() {
      if (this.moreThanOneUserLogout && this.model.id) {
        await this.$store.dispatch('PostDocumentOpenAction',{table:'Role',id:this.model.id,is_locked:false});
        this.$store.dispatch('LogOut');
        this.$router.push({ name: 'login' });
      }
    }
  },
  async created() {
    window.addEventListener('beforeunload', this.handler)
  },
  beforeDestroy() {
    if (this.model.id && !this.moreThanOneUserLogout && this.model.is_locked && this.model.user_id == this.user.id || this.model.id && !this.model.user_id && !this.moreThanOneUserLogout) {
      this.$store.dispatch('PostDocumentOpenAction',{table:'Role',id:this.model.id,is_locked:false});
    }
    this.$refs.form ? this.$refs.form.reset() : '';
    this.model = {},
    this.$store.commit('SET_ROLE_BY_ID', {});
    this.$store.commit('SET_USER_LOGOUT', false)
    window.removeEventListener('beforeunload', this.handler)
  },
  methods: {
    async handler(event) {
      if (this.model.id && !this.moreThanOneUserLogout && this.model.is_locked && this.model.user_id == this.user.id || this.model.id && !this.model.user_id && !this.moreThanOneUserLogout) {
        await this.$store.dispatch('PostDocumentOpenAction',{table:'Role',id:this.model.id,is_locked:false});
      }
      event.preventDefault();
    },
    callBackFunction() {
      this.$router.push({
        name: "Roles",
        query: {
          ...this.roleQuery,
        },
      });
    },
    lessThanValidation(fieldValue, typeValue, sizeValue, v) {
      return !v || (v && v.length <= sizeValue) || this.$t('formRules.lengthValidMessage', { fieldName: fieldValue, type: typeValue, size: sizeValue });
    },
    async saveRole() {
      if(this.$refs.form.validate()){
        this.loading = true;
        let data;
        if(this.$route.params.id) {
          let oldPermission = [];
          this.model.permissions?.forEach(item => {
            oldPermission.push(item.id);
          });
          let addNewPermissions = this.permission_ids.filter(x => oldPermission.indexOf(x) === -1);
          let deletePermissions = oldPermission.filter(x => this.permission_ids.indexOf(x) === -1);
          // save only update model value using changedFormData from utils
          let saveData = changedFormData(this.model, this.originModel);
          data = await this.$store.dispatch('EditRoles', {role_id: this.$route.params.id, role: saveData, addNewPermissions: addNewPermissions, deletePermissions: deletePermissions}).catch((e) => {
            this.loading = false;
            console.log(e)
          });
          if(data) {
            this.$refs.form.reset();
            this.loading = false;
            await this.$store.dispatch('GetRoleById', data.data.id);
            await this.$store.dispatch('GetAllPermissions');
          }
        } else {
          data = await this.$store.dispatch('SetRoles', {role: this.model, permission_ids: this.permission_ids}).catch((e) => {
            this.loading = false;
            console.log(e)
          });
          if(data) {
            this.$refs.form.reset();
            this.loading = false;
            await this.$router.push('/role/editRole/' + data.data.id).catch(()=>{});
            await this.$store.dispatch('GetRoleById', data.data.id);
            await this.$store.dispatch('GetAllPermissions');
          }
        }
      }
    }
  }
}
</script>
